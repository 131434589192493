import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-39645da0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-container" }
const _hoisted_2 = { class: "btn-container" }
const _hoisted_3 = { class: "icon-container" }
const _hoisted_4 = { class: "modal-icon" }
const _hoisted_5 = { class: "modal-name" }
const _hoisted_6 = { class: "modal-message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["modal-body", [_ctx.modalClass]])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          class: "btn-close-modal",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
        }, [
          _createVNode(_component_fa_icon, { icon: ['fas', 'times'] })
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, [
          _renderSlot(_ctx.$slots, "modalIcon", {}, undefined, true)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _renderSlot(_ctx.$slots, "modalName", {}, undefined, true)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _renderSlot(_ctx.$slots, "modalMessage", {}, undefined, true)
      ])
    ], 2)
  ]))
}