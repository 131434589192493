import { _axios } from '@/plugins/axios';
import { AxiosInstance } from 'axios';

class ProjectService {
  private readonly client: AxiosInstance;

  constructor(){
    this.client = _axios;
  }

  async getProjects() {
    const response = await this.client.get('/projects').catch((err) => err.response);
    return response;
  }

  async getProjectDetails(projectId: string) {
    const response = await this.client.get(`/projects/${projectId}`).catch((err) => err.response);
    return response;
  }

  async addProject(data: any) {
    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: 'Bearer <token>',
      },
    };
    const response = await this.client.post('/projects', data, config).catch((err) => err.response);
    return response;
  }

  async updateProject(data: any) {
    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: 'Bearer <token>',
      },
    };
    const response = await this.client.put('/projects', data, config).catch((err) => err.response);
    return response;
  }
  async removeProject(projectId: string) {
    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: 'Bearer <token>',
      },
    };
    const response = await this.client.delete(`/projects/${projectId}`, config).catch((err) => err.response);
    return response;
  }
}

export default new ProjectService();
