
import { defineComponent, onBeforeMount, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'ServiceDetails',
  setup(){
    const appStore = useStore();
    const route = useRoute();
    const service = ref({
      title: 'Service',
      available: false,
      products:[],
      technologies:[]
    })

    onBeforeMount(()=>{
      const serviceName = route.params.service;
      service.value = appStore.state.services.find((sv:  any)=> sv.name === serviceName) || service.value;
    })
    return {
      service
    }
  }
});
