import axios from 'axios';

export default class ContactService {
  static async addToMailList(address: string) {
    const response = await axios
      .post('/api/contacts/mails', {
        mail: address,
      })
      .catch((err) => {
        return err.response;
      });
    return response;
  }
}
