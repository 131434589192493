
import ProjectDetails from '@/components/ProjectDetails.component.vue';
import ProjectService from '@/services/project.service';
import { defineComponent } from 'vue';

// const filters = [{name: 'all', active: false}, {name: 'mobile', active: false}, {name: 'web', active: false}, {name: 'ml', active: false}]

export default defineComponent({
  name: 'Portfolio',
  components: {
    ProjectDetails,
  },
  data() {
    return {
      projects: [],
      active_filter: 'all',
      show_details: false,
      detailed_project: null,
      filter_active: [false],
    };
  },

  methods: {
    filter(filter_name: string) {
      if (filter_name == 'all') {
        this.filtered_projects = this.projects;
        this.filter_active = [true, false, false, false];
        return;
      }
    },

    getRandomImage() {
      const photoPaths = ['layers', 'profile', 'project', '3d'];
      return require(`@/assets/img/${
        photoPaths[Math.floor(Math.random() * photoPaths.length)]
      }.png`);
    },
  },
  computed: {
    filtered_projects(): any {
      if (this.active_filter == 'all') {
        return this.projects;
      }
      return this.projects.filter(
        (project: any) => project.projectType == this.active_filter
      );
    },
  },
  async created() {
    // Fetch Projects
    const response = await ProjectService.getProjects();
    this.projects = response.data;
  },
});
