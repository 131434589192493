import { createStore } from 'vuex'

export default createStore({
  state: {
    services: [
      {
        id: 1,
        name: 'front-end',
        title: 'Front-end Development',
        summary: 'Develop responsive & beautiful UI/UX',
        available: true,
        technologies: ['HTML 5', 'CSS 3', 'JavaScript', 'TypeScript',  'Vue.js', 'Nuxt.js', 'Jquery', 'SCSS', 'Boostrap', 'Tailwind CSS'],
        products: [
          'Single Page Applications',
          'Progressive Web Apps',
          'E-Commerce store front',
          'Social media platform',
          'CRM platform',
        ],
      },
      {
        id: 2,
        name: 'back-end',
        title: 'Back-end Development',
        available: true,
        summary: 'Develop robust and powerful and secure systems to handle any kind of traffic',
        technologies: ['ASP .NET Core', 'Node.js', 'MySQL', 'MSSQL', 'MongoDB', 'Microsoft Azure', 'AWS', 'Heroku'],
        products: [
          'RESTful API services',
          'Micro-services',
          'Monolith applications',
          'Serverless applications',
          'Web application migration',
          'E-Commerce platform',
          'SaaS, PaaS products',
          'Custom CMS',
          'CRM platform'
        ],
      },
      {
        id: 3,
        name: 'ml',
        title: 'Machine Learning Solutions',
        available: false,
        summary: 'Solve real-world problems with the power of machine learning',
        technologies: [],
      },
      {
        id: 4,
        name: 'robotics',
        title: 'Robotics Solutions',
        available: false,
        summary: 'Build at scale with automated systems and integrate AI in your company',
        technologies: [],
      },
    ],
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})