
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ProjectDetails',
  props: {
    project: {
      type: Object as any,
    },
  },
  computed: {
    projectImageUrl(){

      const photoPaths = ['layers', 'profile', 'project', '3d'];

      return this.project?.imageURL || require(`@/assets/img/${photoPaths[Math.floor(Math.random()*photoPaths.length)]}.png`)
    }
  }
});
