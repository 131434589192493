
import { defineComponent } from 'vue';
import baseModal from './base/alert.modal.base.vue';

export default defineComponent({
  name: 'successModal',
  props: {
    message: {
      type: String,
    },
    modalOpen: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  components: {
    baseModal,
  },
  methods: {
    modalClosed() {
      this.$emit('modal-closed');
    },
  },
});
