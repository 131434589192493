
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'baseModal',
  props: {
    modalClass: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    closeModal() {
      this.$emit('modal-closed');
    },
  },
  computed: {},
});
