import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    title: "Click to see details",
    class: _normalizeClass({ 'service--disabled': !_ctx.service.available, service: _ctx.service.available })
  }, [
    _createElementVNode("h3", null, _toDisplayString(_ctx.service?.title), 1),
    _createElementVNode("p", null, _toDisplayString(_ctx.service?.summary), 1)
  ], 2))
}