
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ServiceComponent',
  props: {
    service: {
      type: Object as any,
      required: true,
    },
  },
});
