
import ServiceComponent from '@/components/Service.component.vue';
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'ServicesView',
  components: {
    ServiceComponent,
  },
  setup(){
    const appStore = useStore();
    const services = computed(()=> appStore.state.services);
    return {
      services
    }
  }

});
