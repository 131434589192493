import './plugins/axios';
import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faSearch,
  faBars,
  faHome,
  faShoppingCart,
  faSignInAlt,
  faShareAlt,
  faStar,
  faFilter,
  faPlus,
  faMinus,
  faUser,
  faTimes,
  faSignOutAlt,
  faEdit,
  faTrashAlt,
  faShoppingBasket,
  faBook,
  faLocationArrow,
  faUsersCog,
  faUserShield,
  faThList,
  faList,
  faCheck,
  faCheckCircle,
  faExclamationTriangle,
  faChevronCircleRight,
  faAngleRight,
  faArrowAltCircleLeft,
  faArrowCircleRight,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';

import {
  faTwitter,
  faFacebook,
  faInstagram,
  faYoutube,
  faLinkedin,
  faGithub,
} from '@fortawesome/free-brands-svg-icons';

import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';
import { axiosPlugin } from './plugins/axios';

// Props
// https://github.com/FortAwesome/vue-fontawesome/blob/2.x/src/components/FontAwesomeIcon.js#L39 Props

library.add(
  // brands
  faTwitter,
  faFacebook,
  faInstagram,
  faYoutube,
  faLinkedin,
  faGithub,
  //
  faSearch,
  faBars,
  faHome,
  faShoppingCart,
  faSignInAlt,
  faShareAlt,
  faStar,
  faStarRegular,
  faFilter,
  faPlus,
  faMinus,
  faUser,
  faTimes,
  faSignOutAlt,
  faEdit,
  faTrashAlt,
  faShoppingBasket,
  faBook,
  faLocationArrow,
  faUsersCog,
  faUserShield,
  faThList,
  faList,
  faCheck,
  faCheckCircle,
  faExclamationTriangle,
  faChevronCircleRight,
  faChevronRight,
  faAngleRight,
  faArrowAltCircleLeft,
  faArrowCircleRight
);

const app = createApp(App);
app.component('fa-icon', FontAwesomeIcon);
app.component('fa-layers', FontAwesomeLayers);
app.component('fa-layers-text', FontAwesomeLayersText);

app.use(axiosPlugin).use(store).use(router).mount('#app');
