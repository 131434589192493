
import ContactService from '@/services/contact.service';
import SuccessModal from '@/components/modal/success.modal.vue';
import ErrorModal from '@/components/modal/error.modal.vue';
import WarningModal from '@/components/modal/warning.modal.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Contact',
  components: {
    SuccessModal,
    ErrorModal,
    WarningModal,
  },
  data() {
    return {
      mail: '',
      page_status: {
        loading: false,
        error: {
          message: '',
          status: false,
        },
        success: {
          message: '',
          status: false,
        },
        warning: {
          message: '',
          status: false,
        },
      },
    };
  },
  methods: {
    async handleFormSubmit() {
      const response = await ContactService.addToMailList(this.mail);
      if (response.status == 201) {
        // alert("Your mail has been added successfully");
        this.page_status.success.message = 'Your mail has been added successfuly';
        this.page_status.success.status = true;
        this.mail = '';
      } else {
        this.page_status.warning.message = response.data.message;
        this.page_status.warning.status = true;
      }
    },
  },
});
