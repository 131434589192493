import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-61e529d2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "view-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ServiceComponent = _resolveComponent("ServiceComponent")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.services, (service) => {
      return (_openBlock(), _createElementBlock("div", {
        key: service.id,
        class: "grid-item"
      }, [
        _createVNode(_component_router_link, {
          to: { name: 'service-details', params: { 'service': service.name } }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ServiceComponent, { service: service }, null, 8, ["service"])
          ]),
          _: 2
        }, 1032, ["to"])
      ]))
    }), 128))
  ]))
}